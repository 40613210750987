/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery.marquee@1.5.0/jquery.marquee.min.js
 * - /npm/rellax@1.7.1/rellax.min.js
 * - /npm/plyr@3.7.8/dist/plyr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
